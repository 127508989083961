<template>
	<div>
		<el-row style="padding:20px 0 10px">
			<el-col :span="6"
				>止付金额：{{ allCount.totalSuspendAmount || 0 }}元</el-col
			>
			<el-col :span="6"
				>实际止付金额：{{ allCount.totalActualAmount || 0 }}元</el-col
			>
		</el-row>
		<el-table border v-loading="loading" :data="dataList">
			<el-table-column
				label="代理商/机构编号"
				prop="userNo"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="代理商/机构名称"
				prop="userName"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="订单号"
				prop="orderNo"
				min-width="180"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="止付类型"
				prop="suspendType"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.suspendType | suspendTypeFM }}
				</template>
			</el-table-column>
			<el-table-column
				label="止付金额（元）"
				prop="suspendAmount"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="实际止付金额（元）"
				prop="actualAmount"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="止付状态"
				prop="status"
				min-width="120"
				:show-overflow-tooltip="true"
			>
				<template slot-scope="scope">
					{{ scope.row.status | stopPaymentStatusFM }}
				</template>
			</el-table-column>
			<el-table-column
				label="备注"
				prop="remark"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="创建时间"
				prop="createTime"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<!-- <el-table-column label="完成时间" prop="completeTime" min-width="120" :show-overflow-tooltip="true"/> -->
			<el-table-column
				label="操作人"
				prop="createBy"
				min-width="120"
				:show-overflow-tooltip="true"
			/>
			<el-table-column
				label="操作"
				align="center"
				class-name="small-padding fixed-width"
				width="180"
				fixed="right"
			>
				<template slot-scope="scope">
					<el-button
						size="mini"
						type="text"
						@click="showDialog(scope.row)"
						v-permission="'SUSPEND_ORDER_DELETE'"
						>删除</el-button
					>
				</template>
			</el-table-column>
		</el-table>

		<Pagination
			v-show="total > 0"
			:total="total"
			:page.sync="pageNo"
			:limit.sync="pageSize"
			@pagination="getList"
		/>
		<el-dialog
			title="温馨提示"
			:visible.sync="unbindShow"
			width="400px"
			center
		>
			<div style="padding:20px 0">
				是否确定解除止付，确定后止付订单删除已冻结金额将返回原有账户
			</div>
			<el-form
				ref="savedeleteForm"
				:model="removeSuspendDto"
				label-width="60px"
				style="width:350px"
				:rules="rules"
			>
				<el-form-item label="备注" prop="remark">
					<el-input
						v-model="removeSuspendDto.remark"
						type="textarea"
						placeholder="请最多输入100字"
					/>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="unbindShow = false">取 消</el-button>
				<el-button type="primary" @click="unbindClick">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { FinancialToolsApi } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: function() {
				return {};
			}
		},
		queryParams: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},
	data() {
		return {
			pageNo: 1,
			pageSize: 10,
			loading: false,
			total: 0,
			dataList: [],
			selectRow: {},
			showCustomForm: false,
			isAdd: false,
			unbindShow: false,
			allCount: "",
			remark: "",
			deleList: "",
			removeSuspendDto: {},
			rules: {
				remark: [
					{ required: true, message: "请输入备注", trigger: "blur" }
				]
			}
		};
	},
	mounted() {},
	watch: {
		queryParams: function() {
			this.pageNo = 1;
			this.getList();
		}
	},
	methods: {
		async getList() {
			this.loading = true;
			let result = await FinancialToolsApi.suspendOrder.queryPage(
				this.pageNo,
				this.pageSize,
				this.queryParams
			);
			this.loading = false;
			this.total = result.count || 0;
			this.dataList = result.data.orderPageList || [];
			this.allCount = result.data.orderCountInfo || [];
		},
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.getList();
		},
		async handleUpdate(row) {
			this.showCustomForm = true;
			this.selectRow = row;
			this.isAdd = false;
		},
		async handleDelete() {
			let result = await FinancialToolsApi.suspendOrder.delete(
				this.deleList.orderNo,
				this.removeSuspendDto
			);
			if (result.success) {
				this.Message.success("解除成功");
				this.getList();
				this.unbindShow = false;
				this.removeSuspendDto = {};
			} else {
				this.Message.error(result.message);
			}
		},
		unbindClick() {
			this.$refs.savedeleteForm.validate(valid => {
				if (valid) {
					this.handleDelete();
				} else {
					return false;
				}
			});
		},
		showDialog(row) {
			this.unbindShow = true;
			this.deleList = row;
		}
	}
};
</script>

<style scoped></style>
